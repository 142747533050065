@import "node_modules/pixel-borders/src/styles/pixel-borders/pixel-borders-mixins";

.pixel-border {
  @include pixel-borders($corner-size: 1,
    $border-size: 4px,
    $border-color: #e3ead7,
    $border-inset-color: false);

  &-list-vertical {
    &:first-child {
      border-bottom: 0;
    }

    &:last-child {
      border-top: 0;
    }
  }

  &-list-horizontal {

    &:first-child {
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
    }
  }
}

.pixel-panel {
  transition: all .3s;
  transition: height .3s;

  @include pixel-box($corner-size:1,
    $border-size:4px,
    $background-color:#355e51,
    $border-color: #5f965f,
    $border-inset: true,
    $border-inset-size: false,
    $border-inset-color: false,
    $border-inset-sides: false,
    $border-inset-color-br: false,
    $border-inset-color-tl: false);
  pointer-events: all;
}

.pixel-panel:after {
  pointer-events: none;
}