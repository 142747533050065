@import "/src/Styles/Pixel.scss";

body {
  background-color: #1f1f1f;
  color: #e3ead7;
  font-family: "Press Start 2P";
}

#root {
  height: 100%;
}

svg {
  overflow: "visible" !important;
  transform-origin: 50% 50%;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #e3ead7;
  font-family: "Press Start 2P"
}

p.long-form {
  line-height: 2rem;
}

.home-grid {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1rem auto auto auto 1rem;
}

.detail-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr 2fr;
  grid-template-rows: auto;
}

.character-select-button:first-child {
  border: 4px solid #92b3df;
}

.character-select-button {
  border-right: none;
  border-left: none;
  border-top: 4px solid #92b3df;
  border-bottom: 4px solid #92b3df;
}

.character-select-button:last-child {
  border: 4px solid #92b3df;
}



.borderless {
  border-color: transparent;
}



/* Animation */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

html,
body {
  overscroll-behavior-y: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.button-reset {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {}